export default {
	front: {
		appFront: {
			logins: 'Vos identifiants de connexion',
			warningLoginAccess:
				'Pour renforcer la sécurité de votre compte, vous devez modifier vos identifiants de connexion.',
			warningOwner1: 'Attention :',
			warningOwner2: ' Si vous n’êtes pas le ',
			warningOwner3: 'TITULAIRE DU COMPTE',
			warningOwner4: ` (si vous êtes comptable ou tout autre prestataire),
            merci de ne pas modifier les identifiants. Demandez au titulaire
            de le faire.`,
		},

		navigationBarFront: {
			connectAs: 'connecté en tant que: ',
			connectAsFisc: 'Vous visualisez les données de: ',
			changeUserLogin: 'Changer mes identifiants',
			changeUserPassword: 'Changer mon mot de passe',
			logout: 'Se déconnecter',
			login: 'Se connecter',
			nameTool: 'reporting',
			nameCompany: "l'addition",
			version: 'v 1.2.1',
			sponsorship: 'Parrainez un ami et recevez 100 €',
			downloadFiscal: 'Exporter les données fiscales',
			checkFiscal: 'Vérifier les données fiscales',
			exporPaymentProduct: 'Export ventes produits',
			versionInfos: 'Informations',
		},
		notFound: {
			notFoundMessage: "LA PAGE QUE VOUS CHERCHEZ N'EXISTE PAS.",
		},
	},
	emailEquals: {
		important: 'Important',
		identical: 'Cet email est identique au précédent',
	},
	emailHint: {
		important: 'Important',
		inputEmail:
			"Je saisis un email valide auquel j'ai accès (un email de vérification sera envoyé à cette adresse)",
		notAssociated: "Cet email n'est pas déjà associé à un compte L'Addition",
	},
	passwordEquals: {
		important: 'Important',
		identical: 'Ce mot de passe est identique au précédent',
	},
	passwordHint: {
		title: 'Mon mot de passe doit comporter :',
		nbCharacters: 'au moins 8 caractères',
		uppercase: 'au moins 1 majuscule',
		lowercase: 'au moins 1 minuscule',
		numerical: 'au moins 1 chiffre',
		special: 'au moins 1 caractère spécial',
	},
	flashMessage: {
		readMore: 'Afficher plus',
		readLess: 'Afficher moins',
	},
	notification: {
		noData: "Vous n'avez pas de notifications",
		title: 'Notifications',
	},
	loader: {
		title: 'Veuillez patienter, le chargement du rapport est en cours ...',
		titleBr:
			'Si le rapport n’est pas chargé dans quelques secondes, vous pouvez rafraichir la page',
		title2: 'Chargement en cours',
	},
	faq: {
		title: 'Foire aux questions',
	},

	maintenance: {
		title: 'Page en maintenance',
		partenaireOuilink:
			'Notre partenaire rencontre actuellement des difficultés à afficher L’Addition Achat, le service est indisponible pour le moment.',
	},
	accountMigration: {
		emailValidation: {
			newLoginSuccess: 'Nouveaux identifiants validés',
			newLoginError: 'Erreur',
			newLoginLoading: 'Verification en cours ...',
			pending: 'En cours de verification.',
			error1:
				'La modification de vos identifiants a échoué. Veuillez réessayer.',
			error2:
				"Votre lien a expiré où vous avez déjà validé votre changement d'identifiant.",
			successMessage:
				'Vos identifiants ont bien été modifiés. Vous allez être déconnecté(e) dans quelques instants, merci de vous reconnecter avec vos nouveaux identifiants.',
		},
		invalidPassword: 'Mot de passe incorrect.',
		error: 'Une erreur est survenue.',
		migration: {
			passwordMismatch: 'les mots de passes ne sont pas identiques',
			successMessage:
				"Votre modification est presque terminée. Vous allez recevoir un email de vérification dans quelques instants. Merci de cliquer sur le bouton présent dans l'email pour finaliser la modification de vos identifiants.",
			errorMessage: 'Une erreur est survenue.',
			title: 'Je choisis mes nouveaux identifiants',
			actualEmail: 'Mon email actuel',
			emailInput: 'Je saisis mon email',
			emailConfirm: 'Je confirme mon email',
			passwordInput: 'Je choisis mon mot de passe',
			passwordConfirm: 'Je confirme mon mot de passe',
			errorPassword:
				'Le mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
			errorPasswordRequired: 'Ce champs est requis.',
		},
		btnValidate: 'Valider',
		migrationStep1: {
			actualPassword: 'Je saisis mon mot de passe actuel',
		},
	},
	category: {
		emptyCategory: 'Aucune catégorie disponible !',
	},
	documentation: {
		userManual: {
			pdfName: 'Laddition-Manuel-Utilisateur.pdf',
			description:
				'Le manuel utilisateur rassemble toutes les informations nécessaires à l’utilisation de l’application l’Addition.',
			title: 'Manuel utilisateur',
		},
		installationManual: {
			pdfName: 'Laddition-Manuel-Installation.pdf',
			description:
				'Ce guide vous explique la procédure à suivre pour télécharger, installer et activer l’application sur vos appareils.',
			title: 'Guide - Installer l’application',
		},
		printerLogo: {
			pdfName: 'Laddition-Logo-Imprimante.pdf',
			description:
				'Ce guide vous explique la procédure à suivre pour insérer votre logo dans l’en-tête de vos tickets de caisse et justificatifs.',
			title: 'Guide - Insérer un logo sur vos tickets',
		},
		usbScanner: {
			pdfName: 'LAddition-Scanner-USB.pdf',
			description:
				"Ce guide explique comment connecter le scanner USB Zebra Symbol DS4308 avec L'Addition. Les problèmes les plus courants y sont également décrits avec leur solution.",
			title: 'Guide - Scanner USB',
		},
		qrCodes: {
			pdfName: 'LAddition-QR-codes.pdf',
			description:
				"Ce guide fournit 10 QR codes valides et vous explique comment les intégrer dans votre application L'Addition.",
			title: 'Guide - Ajouter des QR codes',
		},
		zenChef: {
			pdfName: 'LAddition-Zenchef.pdf',
			description:
				"Ce guide explique la procédure à suivre pour activer la synchronisation de vos réservations entre Zenchef et l'application L'Addition.",
			title: 'Guide - Intégrer Zenchef',
		},
		guestOnline: {
			pdfName: 'LAddition-Guestonline.pdf',
			description:
				"Ce guide explique la procédure à suivre pour activer la synchronisation de vos réservations entre Guestonline et l'application L'Addition.",
			title: 'Guide - Intégrer Guestonline',
		},
		lneCertification: {
			pdfName: 'Laddition-attestation-0-lne.pdf',
			description:
				"L'Addition est certifiée conforme à la loi 2018 par le LNE. Téléchargez le certificat n°34277 - révision 0 délivré par le LNE, valable à partir de la version 4.0.0 de l'application.",
			title: 'Certificat LNE de conformité à la loi 2018',
		},
		title: "La bibliothèque L'Addition",
		by: 'De ',
		download: 'Télécharger',
	},
	faq: {
		emptyFaq: 'Aucune faq disponible !',
	},
	partnership: {
		generateKey: 'Générer la clé',
		notActivatedPartner: "Vous n'avez pas activé ce partenaire",
		inActivationPartner: "En attente d'activation du partenaire",
		keyCopied: 'La clé est copiée dans votre presse papier.',
		copyKey: 'Copier la clé partenaire',
		activatedPartner: 'Vous avez activé ce partenaire',
		emptyCategory: 'Aucune catégorie disponible !',
		activatePartnerModules: 'Activation des modules partenaires',
		processTitle: 'Comment ça marche ?',
		process1: 'L’activation d’un partenaire se fait en 3 étapes simples :',
		process2: 'Cliquer sur le nom du partenaire',
		process3: 'Générer une clé',
		process4: 'Renseigner cette clé sur l’interface du partenaire',
		process5:
			'Une fois la clé enregistrée sur l’interface du partenaire, il nous notifiera de la validation ou du refus de l’activation.',
		process6:
			"Vous trouverez la procédure complète dans l'espace : Documentation",
		noIntegration: 'Aucune intégration',
		waitValidation: 'En attente de validation',
		integrationValidated: 'Intégration validée',
		noTokenYet: "Ce partenaire n'a pas généré de Token pour le moment.",
		update_version:
			'Vous devez mettre à jour votre/vos ipad(s) pour vous lier à ce partenaire',
	},
	video: {
		title: 'Modules Vidéos',
		playing: 'En lecture',
		alreadySeen: 'Déjà visionnée',
		pause: 'En pause',
		videoEmpty: 'Aucune vidéo disponible !',
	},
	fisc: {
		table: {
			previousText: 'Précédente',
			nextText: 'Suivante',
			loadingText: 'En chargement...',
			noDataText: 'Aucune donnée trouvée',
			pageText: 'Page',
			ofText: 'de',
			rowsText: 'lignes',
		},
		modalFisc: {
			waitingCheckData:
				'Veuillez patienter, vos données sont en cours de vérification.',
			checkingData: 'Vérification des données',
			csvFile: 'Fichier de données (.csv) :',
			company: 'Société:',
			user: 'Utilisateur:',
			email: 'Email:',
			exportDate: "Date d'export:",
			dataFrom: 'Données du ',
			to: ' au ',
			keyVerification: 'Clé de vérification (.txt) :',
			checkBtn: 'Vérifier les données',
			waitingDownloadData:
				'Veuillez patienter, vos données sont en cours de préparation',
			exportSavedData: 'Export des données archivées',
			begin: 'Début',
			end: 'Fin :',
			exportCashing: "Exporter données d'encaissement",
			exportClosing: 'Exporter données clôtures',
			hashIsGood:
				'Le fichier n’a subi aucune modification, son intégrité est assurée',
			hashIsBad:
				'Le fichier a subi des modifications, son intégrité n’est pas assurée',
			exportErrorOneYearMax: 'La période ne doit pas dépasser un an.',
			exportErrorReversedDates:
				'La date de début est supérieure à la date de fin',
			exportHistoryIsNotExact:
				"L'exactitude des informations ci-dessous n'est pas assurée",
		},

		//TODO LATER
		// downloadExport: " Extraire le tableau",
		// headersTable: {
		//   id: "ID",
		//   name: "Nom"
		// },
		// actionName: {
		//   create: "Création",
		//   update: "Mise à jour",
		//   delete: "Suppression"
		// },
		// index: {
		//   months: {
		//     january: "Janvier",
		//     february: "Février",
		//     march: "Mars",
		//     april: "Avril",
		//     may: "Mai",
		//     june: "Juin",
		//     july: "Juillet",
		//     august: "Août",
		//     september: "Septembre",
		//     october: "Octobre",
		//     november: "Novembre",
		//     december: "Décembre"
		//   },
		//   loader: {
		//     timeLeft: "Temps restant",
		//     calcul: "En cours de calcul",
		//     minute: "minute(s)",
		//     seconde: "seconde(s)"
		//   }
		// }
	},
	informations: {
		title: 'Informations',
		reportingVersion: 'Version Reporting Web UI (logiciel): ',
		reportingServerVersion: 'Version Reporting Web Serveur (logiciel): ',
		fiscModuleVersion: 'Version Module Fiscal Web UI: ',
		fiscModuleServerVersion: 'Version Module Fiscal Web Serveur: ',
	},
	notFound: {
		title: 'Oups !',
		description: "La page demandée n'a pas pu être trouvée",
		backLogin: "Retourner sur la page d'accueil",
	},
	lneExport: {
		noData: 'La période sélectionnée ne comporte aucune donnée',
		exportProcessing:
			"Votre export est en cours, vous serez informé par e-mail lorsqu'il sera prêt !",
		emailHint: 'Je saisis mon e-mail',
	},
	customerAccount: {
		cardNumber: 'N° carte',
		export: 'Exporter',
		exportCustomerDatas: 'EXPORT DES DONNÉES CLIENTS',
		exportAllAccount: 'Exporter liste des comptes',
		exportCustomerUnpaidSales: 'Exporter tickets non-soldés',
		customerAccountTitle: 'Comptes clients',
		noAccountFound: "Aucun compte n'a été trouvé",
		firstAndLastName: 'Nom et prénom',
		name: 'Nom',
		firstname: 'Prénom',
		email: 'Email',
		countryCode: 'Indicatif',
		phoneNumber: 'Téléphone',
		address: 'Adresse',
		additionalAddress: "Complément d'adresse",
		zipCode: 'Code postal',
		city: 'Ville',
		additionalInformation: 'Informations complémentaires',
		society: 'Société',
		tvaNumber: 'N° de TVA',
		siren: 'N° siren',
		companyName: 'Établissement',
		balance: 'Solde',
		visit: 'Visites',
		order_average: 'Ticket moyen',
		guest_average: 'Panier moyen',
		nb_guests: 'Couverts',
		turnover: 'Total TTC',
		discount: 'Total remises',
		offered: 'Total offerts',
		turnoverDiscountOffered: 'Total TTC Brut',
		displayStatistics: 'Afficher les Statistiques',
		search: 'Recherche',
		allCompanies: 'Tout établissement',
		accountsDetails: 'Détail du compte',
		of: 'du',
		notOnSold: 'Non soldés',
		sold: 'Soldés',
		credits: 'Crédits',
		oneCredits: 'Crédits',
		selectPeriod: 'Sélectionner une période',
		resetPeriod: 'Réinitialiser le calendrier',
		noCreditFound: 'Aucun crédit trouvé',
		noTicketfound: 'Aucun ticket trouvé',
		ticketDate: 'Date du ticket',
		paymentDate: 'Date du paiement',
		user: 'Utilisateur',
		selectAll: 'Tout sélectionner',
		mailSender: 'Expéditeur',
		mailRecipient: 'Destinataire',
		mailSubject: 'Objet',
		mailContent: 'Message',
		mailAttachement: 'pièce(s)-jointe(s)',
		mailSend: 'Envoyer',
		noTicketSelected: 'Aucun ticket sélectionné',
		errorTicketGeneration:
			'Oups... Nous rencontrons un problème pour générer le ticket.',
		customerCredit: 'Crédit',
		onTicket: '1 Ticket du',
		oneCredit: '1 Crédit',
		amountUsed: 'montant utilisé',
		creditLoadDataError:
			"Oups... nous rencontrons un problème avec l'affichage des données du crédit. Recharger la page ou retenter plus tard.",
		noPaymentLine: "Aucun ticket n'est lié à ce crédit.",
		noFoundCredit: 'Pas de crédit disponible',
		selectOneCredit: 'Sélectionner au moins un crédit',
		pageFormat: 'Format A4',
		toPageFormat: 'au format A4',
		dayByDay: 'Jour par jour',
		defaultLabel: 'Libellé par défaut :',
		sendTicketPDF: 'Envoyer chaque ticket en tant que PDF séparé',
		ticketDetails: 'Détaillé',
		ticketNoDetails: 'Non détaillé',
		ticketDetailsMultiple: 'détaillé(s)',
		ticketNoDetailsMultiple: 'non détaillé(s)',
		ticketSend: 'Envoyer',
		ticketSendCorrectly: 'Message bien envoyé !',
		sendMailError:
			"Oups nous avons rencontré un problème avec l'envoi du mail...",
		amountCredit: 'Montant du crédit',
		amountIvat: 'Montant TTC',
		paidAt: 'Payé le ',
		a: 'à',
		hello: 'Bonjour ',
		and: ' et ',
		yourBill: 'Votre facture',
		labelDetailsDefault: 'Repas complet(s)',
		defaultSendMail:
			", vous trouverez ci-jointe(s) la/les facture(s) pour l'établissement",
		customerAccountManagement: "Activer la gestion des comptes clients sur iPad",
		deactivationOnBalanceOverrun: "Désactivation automatique sur dépassement de solde",
		preventPaying: "Empêcher le règlement supérieur au montant dû",
		preventNegativeBalance: "Empêcher un solde négatif après mise en compte",
		preventPaidTickets: "Empêcher le règlement des tickets en cours de service",
		preventCountingCustomers: "Empêcher la mise en compte sur les nouveaux clients",
		autoEmailNewTicket: "Envoyer automatiquement un ticket mis sur l'ardoise du client à la clôture de caisse",
		customerBic: "BIC",
		customerIban: "IBAN",
		bicAndIbanInfo: "Le BIC et l'IBAN sont inscrits au bas des factures simplifiées.",
		balance: "Solde",
		customersBalance: "Tous les soldes",
		customersDebitBalance: "Solde débiteur (inférieur à 0)",
		customersZeroBalance: "Solde nul (= 0)",
		customersCreditBalance: "Solde créditeur (suprérieur à 0)",
		informations: "Informations",
		customersInformations: "Toutes les informations",
		phoneNumberEntered: "Numéro de téléphone renseigné",
		emailEntered: "Adresse e-mail renseignée",
		withoutEmailOrPhone: "Sans email ni téléphone",
		visits: "Visites",
		customersVisits: "Toutes les visites",
		noVisit: "0 visite",
		between1And10Visits: "Entre 1 et 10 visites",
		between11And50Visits: "Entre 11 et 50 visites",
		between51And100Visits: "Entre 51 et 100 visites",
		moreThan100Visits: "Supérieur à 100 visites",
		settingsSavedWithSuccess: "Les paramètres ont été modifiés avec succès.",
		clearFilters: "Effacer les filtres",
	},
	globalLabels: {
		filters: 'Filtres',
		date: 'Date',
		companies: 'Établissement(s)',
		allCompanies: 'Tous les établissements',
		shifts: 'Service(s)',
		allShifts: 'Tous les services',
		validate: 'Valider',
		noData: 'Aucunes données',
		services: 'Détails rapport',
	},

	barDistrib: {
		products: 'Produits',
		totalProductsCashed: 'Total produits encaissés',
		waresShift: 'Tirages services',
		waresOutShift: 'Tirages hors services',
		totalWares: 'Total tirages',
		difference: 'Différence',
		wares: 'Tirages',
	},

	barDistribDetail: {
		product: 'Produit',
		totalProductsCashed: 'Encaissés',
		user: 'Poste de tirage',
		wareHour: 'Heure de tirage',
		totalProductsNotCashed: 'Quantités non encaissées',
		difference: 'Différence',
		title: 'Rapport detaillé : ',
		titleNotCashed: 'Tirages non encaissés durant service',
		titleOutShift: 'Tirages hors service',
		titleProductsCashed: 'Produits encaissés',
	},
	exportReport: {
		exportDataTitle: 'Export des données',
		exportType: `Type d'export`,
		exportOrderData: 'Export lignes de ventes',
		exportAccountType: 'Type de compte',
		accounts: 'Comptes',
	},
};
