import React, { useState } from 'react';
import { Dropdown, Text } from '@capps/laddition-storybook';
import './MultipleSelect.css';

const MultipleSelect = ({ data, label, onCheckboxChange, selectedItems }) => {
  const handleCheckboxChange = (id) => {
    const isChecked = !selectedItems.includes(id);
    if (onCheckboxChange) {
      onCheckboxChange(id, isChecked);
    }
  };

  return (
    <Dropdown
      triggerLabel={label}
      align="left"
      closeClassNames={['dropdown__item']}
      controlled
      onClickOutside={() => {}}
      onTriggerClick={() => {}}
    >
      <div className="dropdown-content">
        {data.map(item => (
          <div key={item.id} className="p-2 flex">
            <input
              className="dropdown-checkbox"
              type="checkbox"
              id={item.id}
              name={item.id}
              checked={selectedItems.includes(item.id)}
              onChange={() => handleCheckboxChange(item.id)}
            />
            <Text className="dropdown-text" color="main" variant="h6">
              {item.label}
            </Text>
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default MultipleSelect;
