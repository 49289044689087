import React from 'react';
import config from '../../../utils/config';

function Settings() {
  return (
    <div id="main-container" className="main-container nav-effect-1">
      <iframe
        style={{ top: '0px' }}
        id="ouilink-iframe"
        src={`${config.reportingV4Url}settings`}
      />
    </div>
  );
}
export default Settings;