import React, { useState } from 'react';
import MultipleSelect from '../../../components/Select/MultipleSelect';
import { I18n } from 'react-redux-i18n';

const StatsFilters = ({ label, onColumnVisibilityChange }) => {
  const data = [
    { id: 'visit', label: I18n.t('customerAccount.visit') },
    { id: 'order_average', label: I18n.t('customerAccount.order_average') },
    { id: 'guest_average', label: I18n.t('customerAccount.guest_average') },
    { id: 'nb_guests', label: I18n.t('customerAccount.nb_guests') },
    { id: 'turnover', label: I18n.t('customerAccount.turnover') },
    { id: 'discount', label: I18n.t('customerAccount.discount') },
    { id: 'offered', label: I18n.t('customerAccount.offered') },
    { id: 'total', label: I18n.t('customerAccount.turnoverDiscountOffered') },
  ];

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedItems((prev) =>
      isChecked ? [...prev, id] : prev.filter(item => item !== id)
    );

    if (onColumnVisibilityChange) {
      onColumnVisibilityChange(id, isChecked);
    }
  };

  const handleSelectAllChange = () => {
    const isSelectingAll = selectedItems.length !== data.length;

    if (isSelectingAll) {
      setSelectedItems(data.map(item => item.id));
      data.forEach(item => {
        if (onColumnVisibilityChange) {
          onColumnVisibilityChange(item.id, true);
        }
      });
    } else {
      setSelectedItems([]);
      data.forEach(item => {
        if (onColumnVisibilityChange) {
          onColumnVisibilityChange(item.id, false);
        }
      });
    }
  };

  const areAllItemsSelected = selectedItems.length === data.length;

  return (
    <div>
      <MultipleSelect
        label={label}
        data={[
          {
            id: 'select_all',
            label: I18n.t('customerAccount.selectAll'),
            isVisible: areAllItemsSelected,
          },
          ...data,
        ]}
        onCheckboxChange={(id, isChecked) => {
          if (id === 'select_all') {
            handleSelectAllChange();
          } else {
            handleCheckboxChange(id, isChecked);
          }
        }}
        selectedItems={areAllItemsSelected ? ['select_all', ...selectedItems] : selectedItems}
      />
    </div>
  );
};

export default StatsFilters;
