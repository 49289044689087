import React from 'react';
import MultipleSelect from '../../../components/Select/MultipleSelect';

const TableFilters = ({ label, data, activeFilterIds, onRowVisibilityChange }) => {
  const selectedItems = activeFilterIds;

  const handleCheckboxChange = (id, isChecked) => {
    if (onRowVisibilityChange) {
      onRowVisibilityChange(id, isChecked);
    }
  };

  return (
    <div>
      <MultipleSelect
        label={label}
        data={data}
        onCheckboxChange={handleCheckboxChange}
        selectedItems={selectedItems}
      />
    </div>
  );
};

export default TableFilters;
