import React, { Component } from 'react';

import memoize from 'lodash/memoize';
import '../sass/customerAccount.css';
import CallApi from './../../../utils/CallApi';
import TableLoader from './../../../common/tableloader/TableLoader';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import { a } from 'react-router';
import CarteImg from '../img/solde_plein@2x.png';
import ImgRemove from '../img/delete.png';
import ImgSettings from '../img/settings-white-icon.png';
import ImgSearch from '../img/search-icon.png';
import ImgTrash from '../img/trash.png';
import ImgPencil from '../img/pencil.png';
import ImgFilter from '../img/filter-icon.png';
import ImgStats from '../img/stats-icon.png';
import ImgCircleCross from '../img/cross-circle-blue1.png';
import removeAccents from 'remove-accents';
import { connect } from 'react-redux';
import config from '../../../utils/config';
import priceFormatting from '../../../utils/priceFormatting';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import Clusterize from '../components/Clusterize';
import ModalExport from '../components/ModalExport';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import A_B_Tester from '../../../components/A_B_Tester';
import ModalHistory from '../components/ModalHistory';
import ModalSettings from '../components/ModalSettings';
import ModalAccountForm from '../components/ModalAccountForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import StatsFilters from '../components/StatsFilters';
import TableFilters from '../components/TableFilters';
import { Text } from '@capps/laddition-storybook';

const language = window.navigator.language;

class CustomerAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listAccounts: [],
			listAccountsFiltered: [],
			searchValue: '',
			loading: true,
			resetSearch: false,
			idOrderedBy: '',
			orderDirection: 'ASC',
			openExportModal: false,
			openExportHistory: false,
			allUsers: [],
			paymentTypes: [],
			openSettings: false,
			selectedRows: [],
			selectAll: false,
			modalOpen: false,
			openAccountForm: false,
			selectedAccountData: null,
			hasBalance: false,
			isFiltered: false,
			selectedCol: [],
			columnsVisible: {
				visit: false,
				order_average: false,
				guest_average: false,
				nb_guests: false,
				turnover: false,
				discount: false,
				offered: false,
				total: false,
			},
			activeFilterIds: [],
		};

		this.filtersData = [
			{
				label: 'Solde',
				options: [
					{ id: 'customers_debit_balance', label: I18n.t('customerAccount.customersDebitBalance'), filter: (account) => account.account_details.balance < 0 },
					{ id: 'customers_zero_balance', label: I18n.t('customerAccount.customersZeroBalance'), filter: (account) => account.account_details.balance === 0 },
					{ id: 'customers_credit_balance', label: I18n.t('customerAccount.customersCreditBalance'), filter: (account) => account.account_details.balance > 0 },
				],
			},
			{
				label: 'Informations',
				options: [
					{ id: 'customers_phone_number_entered', label: I18n.t('customerAccount.phoneNumberEntered'), filter: account => account.customer_infos.details_phone },
					{ id: 'customers_email_entered', label: I18n.t('customerAccount.emailEntered'), filter: account => account.customer_infos.details_email },
					{ id: 'customers_without_email_or_phone', label: I18n.t('customerAccount.withoutEmailOrPhone'), filter: account => !account.customer_infos.details_phone && !account.customer_infos.details_email },
				],
			},
			{
				label: 'Visites',
				options: [
					{ id: 'customers_no_visit', label: I18n.t('customerAccount.noVisit'), filter: account => account.account_details.visit === 0 },
					{ id: 'customers_1_and_10_visits', label: I18n.t('customerAccount.between1And10Visits'), filter: account => account.account_details.visit >= 1 && account.account_details.visit <= 10, },
					{ id: 'customers_11_and_50_visits', label: I18n.t('customerAccount.between11And50Visits'), filter: account => account.account_details.visit >= 11 && account.account_details.visit <= 50, },
					{ id: 'customers_51_and_100_visits', label: I18n.t('customerAccount.between51And100Visits'), filter: account => account.account_details.visit >= 51 && account.account_details.visit <= 100, },
					{ id: 'customers_credit_more_than_100_visits', label: I18n.t('customerAccount.moreThan100Visits'), filter: account => account.account_details.visit > 100 },
				],
			},
		];

		this.headersLabels = [
			{
				id: 'card_number',
				cssClass: 'small',
				field: 'card_number',
				childField: null,
				label: `${I18n.t('customerAccount.cardNumber')}`,
			},
			{
				id: 'lastname',
				cssClass: 'big',
				field: 'lastname',
				childField: null,
				label: `${I18n.t('customerAccount.firstAndLastName')}`,
			},
			{
				id: 'details_email',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'details_email',
				label: `${I18n.t('customerAccount.email')}`,
			},
			{
				id: 'details_phone',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'details_phone',
				label: `${I18n.t('customerAccount.phoneNumber')}`,
			},
			{
				id: 'adress',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'adress',
				label: `${I18n.t('customerAccount.address')}`,
			},
			{
				id: 'customer_company_id',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'customer_company_id',
				label: `${I18n.t('customerAccount.siren')}`,
			},
			{
				id: 'company_name',
				cssClass: 'big',
				field: 'companies',
				childField: 'company_name',
				label: `${I18n.t('customerAccount.companyName')}`,
			},
			{
				id: 'balance',
				cssClass: 'small',
				field: 'account_details',
				childField: 'balance',
				label: `${I18n.t('customerAccount.balance')}`,
			},
			{
				id: 'visit',
				cssClass: 'small',
				field: 'account_details',
				childField: 'visit',
				label: `${I18n.t('customerAccount.visit')}`,
			},
			{
				id: 'order_average',
				cssClass: 'small',
				field: 'account_details',
				childField: 'order_average',
				label: `${I18n.t('customerAccount.order_average')}`,
			},
			{
				id: 'guest_average',
				cssClass: 'small',
				field: 'account_details',
				childField: 'guest_average',
				label: `${I18n.t('customerAccount.guest_average')}`,
			},
			{
				id: 'nb_guests',
				cssClass: 'small',
				field: 'account_details',
				childField: 'nb_guests',
				label: `${I18n.t('customerAccount.nb_guests')}`,
			},
			{
				id: 'turnover',
				cssClass: 'small',
				field: 'account_details',
				childField: 'turnover',
				label: `${I18n.t('customerAccount.turnover')}`,
			},
			{
				id: 'discount',
				cssClass: 'small',
				field: 'account_details',
				childField: 'discount',
				label: `${I18n.t('customerAccount.discount')}`,
			},
			{
				id: 'offered',
				cssClass: 'small',
				field: 'account_details',
				childField: 'offered',
				label: `${I18n.t('customerAccount.offered')}`,
			},
			{
				id: 'total',
				cssClass: 'small',
				field: 'account_details',
				childField: 'total',
				label: `${I18n.t('customerAccount.turnoverDiscountOffered')}`,
			},
		];
	}

	componentDidMount() {
		this.getListCustomerAccounts();
		this.getAllUsers();
		this.getAllPaymentTypes();
		this.checkEspaceClientGroup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.searchValue !== this.state.searchValue) {
			window.sessionStorage.setItem('searchValue', this.state.searchValue);
		}

		if (
			prevState.listAccounts.length !== this.state.listAccounts.length &&
			window.sessionStorage.getItem('searchValue') !== 'null'
		) {
			const searchValueStorage =
				window.sessionStorage.getItem('searchValue') !== 'null'
					? window.sessionStorage.getItem('searchValue')
					: '';
			this.setState(
				{
					searchValue: searchValueStorage,
					loading: true,
				},
				() => {
					this.filterAccounts(searchValueStorage);
				}
			);
		}

		if (prevProps.auth.user !== this.props.auth.user) {
			this.checkEspaceClientGroup();
		}
	}

	getListCustomerAccounts = async () => {
		try {
			const accountsList = await CallApi('GET', 'customer-account', '', '');
			this.setState({
				listAccounts: accountsList,
				listAccountsFiltered: accountsList,
				loading: false,
			});
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	deleteSelectedAccounts = async customerIds => {
		try {
			await CallApi('POST', 'customer-account/customers/removed', '', {
				customerIds,
			});
		} catch (error) {
			console.error('Error deleting accounts:', error);
		}
	};

	getAllUsers = async () => {
		try {
			const getUsers = await CallApi('GET', 'user/users', '', '');

			this.setState({ allUsers: getUsers[0] });
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	checkEspaceClientGroup() {
		const user = this.props.auth.user;
		if (user && user.groups) {
			const hasEspaceClientGroup = user.groups.some(
				group => group.name === 'espace_client'
			);
			this.setState({ hasEspaceClientGroup });
		}
	}

	getAllPaymentTypes = async () => {
		try {
			const getPaymentTypes = await CallApi('GET', 'user/paymentTypes', '', '');
			this.setState({ paymentTypes: getPaymentTypes[0] });
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	searchAccounts = e => {
		e.preventDefault();
		const searchValue = e.target.value;
		this.setState({ searchValue, loading: true }, () => {
			setTimeout(() => {
				this.filterAccounts(searchValue);
			}, 500);
		});
	};

	setOrderTable = (e, field, childField, id) => {
		e.preventDefault();
		this.setState(
			(prevState, props) => ({
				loading: true,
				idOrderedBy: id,
				orderDirection:
					prevState.idOrderedBy === id && prevState.orderDirection === 'ASC'
						? 'DESC'
						: 'ASC',
			}),
			() => {
				setTimeout(
					() => this.orderTable(this.state.listAccounts, field, childField),
					500
				);
			}
		);
	};

	orderTable = (accountList, field, childField) => {
		const sort_by = (field, fieldInObject, reverse = 'ASC', primer) => {
			const key = function (x) {
				const val =
					fieldInObject !== null && x[field.toString()]
						? field === 'companies'
							? x[field.toString()].length > 1
								? 'ALL'
								: x[field.toString()][0][fieldInObject]
							: x[field.toString()][fieldInObject]
						: x[field];
				return primer ? primer(val) : val;
			};

			const order = reverse === 'ASC' ? 1 : -1;
			return function (a, b) {
				return (
					(a = key(a) === null ? '' : key(a)),
					(b = key(b) === null ? '' : key(b)),
					order * ((a > b) - (b > a))
				);
			};
		};

		const sortedData = accountList[0].sort(
			sort_by(field, childField, this.state.orderDirection, a =>
				typeof a === 'string' ? a.toUpperCase() : a
			)
		);

		this.setState({
			loading: false,
			listAccountsFiltered: [sortedData],
		});
	};

	filterAccounts = (searchValue) => {
		const { listAccounts, activeFilterIds } = this.state;

		const filtersToApply = this.filtersData
			.flatMap((group) =>
				activeFilterIds.map(
					(id) => group.options.find((option) => option.id === id)?.filter
				)
			)
			.filter(Boolean);

		const accountsListFiltered = (listAccounts || []).flat().filter((account) => {
			const search = account?.search?.toLowerCase() || '';
			const searchValueLower = searchValue?.toLowerCase() || '';

			return (
				(!searchValue || search.includes(searchValueLower)) &&
				(filtersToApply.length === 0 || filtersToApply.some((filterFunction) => filterFunction(account)))
			);
		});

		this.setState({
			loading: false,
			listAccountsFiltered: [accountsListFiltered],
			selectedRows: [],
			selectAll: false,
		});
	};

	handleModalExport = e => {
		this.setState({ openExportModal: true });
	};

	handleResetClick = e => {
		e.preventDefault();
		this.setState(
			{
				searchValue: '',
				resetSearch: true,
				loading: true,
				listAccountsFiltered: this.state.listAccounts,
			},
			() => {
				this.filterAccounts('');
				this.setState({ loading: false });
			}
		);
	};

	handleClearFilters = () => {
		this.setState(
			{
				isFiltered: false,
				activeFilterIds: [],
				listAccountsFiltered: this.state.listAccounts,
			},
			() => {
				this.filterAccounts(this.state.searchValue);
				this.setState({ loading: false });
			}
		);
	};

	handleCheckboxChange = id => {
		this.setState(prevState => {
			const isAlreadySelected = prevState.selectedRows.includes(id);

			const newSelectedRows = isAlreadySelected
				? prevState.selectedRows.filter(rowId => rowId !== id)
				: [...prevState.selectedRows, id];

			const selectedAccount =
				!isAlreadySelected &&
				prevState.listAccountsFiltered
					.flat()
					.find(account => account.id === id);

			const hasBalance =
				(!isAlreadySelected && selectedAccount?.account_details?.balance > 0) ||
				selectedAccount?.account_details?.balance < 0;

			return {
				selectedRows: newSelectedRows,
				selectedAccountData: selectedAccount,
				hasBalance: hasBalance,
			};
		});
	};

	handleSelectAll = () => {
		const data = this.state.listAccountsFiltered.flat();
		const dataWithoutBalance = data.filter(
			account =>
				account.account_details.balance === 0 ||
				account.account_details.balance === null
		);

		const selectAll = !this.state.selectAll;
		const newSelectedRows = selectAll
			? dataWithoutBalance.map(row => row.id)
			: [];

		this.setState({
			selectedRows: newSelectedRows,
			selectAll: selectAll,
		});
	};

	isRowSelected = id => {
		return this.state.selectedRows.includes(id);
	};

	handleDelete = async () => {
		const selectedIds = this.state.selectedRows;
		try {
			await this.deleteSelectedAccounts(selectedIds);
			const updatedList = this.state.listAccountsFiltered[0]
				? this.state.listAccountsFiltered[0].filter(
						account => !selectedIds.includes(account.id)
				  )
				: [];

			this.setState({
				listAccountsFiltered: [updatedList],
				selectedRows: [],
				modalOpen: false,
			});
			this.props.addFlashMessage({
				type: 'success',
				text: 'Votre sélection a été supprimée avec succès.',
				persistent: false,
			});
		} catch (error) {
			console.error('Error during delete :', error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue lors de la supression.',
				persistent: false,
			});
		}
	};

	handleOpenModal = () => {
		this.setState({ modalOpen: true });
	};

	handleCloseModal = () => {
		this.setState({
			modalOpen: false,
		});
	};

	handleOpenModalWithData = () => {
		this.setState({
			openAccountForm: true,
		});
	};

	handleFormSuccess = async () => {
		try {
			await this.getListCustomerAccounts();
			this.setState({
				openAccountForm: false,
				selectedRows: [],
			});
			this.props.addFlashMessage({
				type: 'success',
				text: 'Le compte a été modifié avec succès.',
				persistent: false,
			});
		} catch (error) {
			console.error('Error during update :', error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue lors de la mise à jour des informations.',
				persistent: false,
			});
		}
	};

	handleSelectionChange = (newSelectedCol) => {
		this.setState({ selectedCol: newSelectedCol });
	};

	handleColumnVisibilityChange = (columnId, isVisible) => {
    this.setState(prevState => ({
      columnsVisible: {
        ...prevState.columnsVisible,
        [columnId]: isVisible,
      },
    }));
  };

handleRowVisibilityChange = (id, isChecked) => {
	this.setState(
		(prevState) => {
			const updatedFilters = isChecked
				? [...prevState.activeFilterIds, id]
				: prevState.activeFilterIds.filter((filterId) => filterId !== id);

			const isFiltered = updatedFilters.length > 0;

			return { activeFilterIds: updatedFilters, isFiltered };
		},
		() => {
			this.filterAccounts(this.state.searchValue);
		}
	);
};


	exportCustomerData = async (
		startDate = '',
		endDate = '',
		route,
		userId = '',
		method,
		email
	) => {
		try {
			const customerAccountExport = await CallApi(
				method,
				`customer-account/${route}`,
				'',
				{
					startDate,
					endDate,
					email,
				}
			);

			if (customerAccountExport && customerAccountExport.length > 0) {
				let message =
					customerAccountExport[0].ok && customerAccountExport[0].data
						? I18n.t('lneExport.exportProcessing')
						: I18n.t('lneExport.noData');
				this.props.addFlashMessage({
					type: message.type,
					text: message,
					persistent: false,
				});
			}
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
			return error;
		}
	};

	createTableHeader = () => {
		return this.headersLabels
			.filter(head => {
				if (this.props.isSuite && head.id === 'card_number') {
					return false;
				}
				if (this.state.columnsVisible[head.id] === false) {
					return false;
				}
				return true;
			})
			.map(head => {
				return (
					<div
						id="header_label"
						key={`${Math.floor(Math.random() * 100000)}`}
						className={`flex-row ${head.cssClass}`}>
						<p
							key={head.id}
							role="columnheader"
							onClick={e =>
								this.setOrderTable(e, head.field, head.childField, head.id)
							}>
							{head.label}
						</p>
						{head.id !== this.state.idOrderedBy ? (
							<RemoveIcon />
						) : this.state.orderDirection === 'ASC' ? (
							<ArrowUpwardIcon />
						) : (
							<ArrowDownwardIcon />
						)}
					</div>
				);
			});
	};

	createAccountRow = () => {
		const data =
			this.state.searchValue || this.state.isFiltered
				? this.state.listAccountsFiltered.flat()
				: this.state.listAccountsFiltered.flat().slice(0, 100);
		const renderCompanies = companies => {
			return companies.length > 1 ? 'Tous' : companies[0].company_name;
		};

		if (data.length > 0) {
			return data.map(account => {
				const accountIds = account.companies.map(
					company => company.uniq_id_customer
				);
				const accountIdCompanies = account.companies.map(company => company.id);
				const nameRestaurantTmp =
					account.companies.length === 1
						? account.companies[0].company_name.split(' ').join('-')
						: '';
				const nameRestaurant = removeAccents(nameRestaurantTmp);

				const url =
					account.companies.length === 1
						? `customer-account/${accountIds}?id=${accountIdCompanies}`
						: `customer-account/${accountIds}`;

				return (
					<tr
						className="flex-table"
						role="rowgroup"
						key={account.id}
						data-url={
							account.companies.length === 1
								? `/customer-account/${accountIds}?id=${accountIdCompanies}`
								: `/customer-account/${accountIds}`
						}>
						{this.props.isSuite && (
							<td className="flex-row check-box-cell" role="cell">
								<input
									type="checkbox"
									data-id={account.id}
									checked={this.isRowSelected(account.id)}
									onChange={() => this.handleCheckboxChange(account.id)}
								/>
							</td>
						)}
						{!this.props.isSuite && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.card_number || ''}</span>
								</a>
							</td>
						)}
						<td className="flex-row big" role="cell">
							<a href={url}>
								<span>{`${account.lastname || ''} ${
									account.firstname || ''
								}`}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={url}>
								<span>{account.customer_infos?.details_email || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={url}>
								<span>{account.customer_infos?.details_phone || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={url}>
								<span>{account.customer_infos?.address || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={url}>
								<span>{account.customer_infos?.customer_company_id || ''}</span>
							</a>
						</td>
						<td className="flex-row big" role="cell">
							<a href={url}>
								<span>
									{account.companies ? renderCompanies(account.companies) : ''}
								</span>
							</a>
						</td>
						<td className="flex-row small" role="cell">
							<a href={url}>
								<span>
									{account.account_details?.balance
										? priceFormatting(
												account.account_details.balance,
												'en',
												'EUR'
										  )
										: ''}
								</span>
							</a>
						</td>
						{this.state.columnsVisible.visit && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.visit || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.order_average && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.order_average || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.guest_average && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.guest_average || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.nb_guests && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.nb_guests || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.turnover && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.turnover || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.discount && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.discount || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.offered && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.offered || ''}</span>
								</a>
							</td>
						)}
						{this.state.columnsVisible.total && (
							<td className="flex-row small" role="cell">
								<a href={url}>
									<span>{account.account_details?.total || ''}</span>
								</a>
							</td>
						)}
					</tr>
				);
			});
		} else {
			return (
				<div id="empty-contact">
					<div id="error-box">
						<div className="face">
							<div className="eye" />
							<div className="eye right" />
							<div className="mouth sad" />
						</div>
						<div className="shadow move" />
						<p className="message">No account found</p>
					</div>
				</div>
			);
		}
	};

	selectFilter = () => {
		return (
			<div className="flex items-center">
				<div className="flex">
					<div className='flex items-center'>
						<img src={ImgStats} alt="Statistics" className="statistics" />
					</div>
					<StatsFilters
						label={I18n.t('customerAccount.displayStatistics')}
						onColumnVisibilityChange={this.handleColumnVisibilityChange}
					/>
				</div>
				<div className="flex filters-select">
					<div className='flex items-center'>
						<img src={ImgFilter} alt="Filter" className="filter" />
					</div>
					<TableFilters
						label={I18n.t('customerAccount.balance')}
						data={this.filtersData[0].options}
						activeFilterIds={this.state.activeFilterIds}
						onRowVisibilityChange={this.handleRowVisibilityChange}
					/>
					<TableFilters
						label={I18n.t('customerAccount.informations')}
						data={this.filtersData[1].options}
						activeFilterIds={this.state.activeFilterIds}
						onRowVisibilityChange={this.handleRowVisibilityChange}
					/>
					<TableFilters
						label={I18n.t('customerAccount.visits')}
						data={this.filtersData[2].options}
						activeFilterIds={this.state.activeFilterIds}
						onRowVisibilityChange={this.handleRowVisibilityChange}
					/>
					{this.state.isFiltered && (
						<button
							className="reset-filters-btn"
							onClick={() => this.handleClearFilters()}>
							<img src={ImgCircleCross} alt="Close" className="cross-circle" />
							<Text className="button-img-text" color="main" variant="h6">{I18n.t('customerAccount.clearFilters')}</Text>
						</button>
					)}
				</div>
			</div>
		);
	};

	render() {

		return (
			<div id="customer-account">
				<h3>{I18n.t('customerAccount.customerAccountTitle')}</h3>
				<header>
					<div className="header-main">
						<div className="customer-header-left">{this.selectFilter()}</div>
						<div className="customer-header-action">
							<button
								type="button"
								onClick={() => this.setState({ openSettings: true })}
								className="settings-btn">
								<p>Paramètres</p>
								<img src={ImgSettings} alt="Settings" className="settings" />
							</button>
							{/* { !this.props.isSuite &&
								<button
									className="btn-history"
									onClick={() => this.setState({ openExportHistory: true })}>
									Historique
								</button>
							} */}
							{/* <A_B_Tester
								featureFlag={'export-customer-account'}
								FeatureFlagComponent={
									<button
										className="header-export-btn"
										// onClick={this.handleModalExport}
									>
										{I18n.t('customerAccount.export')}
									</button>
								}
								DefaultComponent={null}
							/> */}
							<a
								className="a-history"
								href="https://laddition.slite.page/p/pAnyxHbOz_zgIk/Historique-des-comptes-clients"
								target="_blank">
								<Text color="main" variant="h5">Historique</Text>
							</a>

							<div className="search-bar-account">
								{this.state.searchValue ? (
									<img
										src={ImgCircleCross}
										onClick={this.handleResetClick}
										className="remove"
										alt="img remove"
									/>
								) : (
									''
								)}
								<input
									type="text"
									placeholder={I18n.t('customerAccount.search')}
									value={this.state.searchValue ? this.state.searchValue : ''}
									onChange={this.searchAccounts}
								/>
								<div>
									<img src={ImgSearch} alt="Search" className="search" />
								</div>
							</div>
						</div>
					</div>
				</header>
				{this.state.selectedRows.length > 0 && this.props.isSuite && (
					<div className="row-actions">
						<p>
							{this.state.selectedRows.length > 1
								? `${this.state.selectedRows.length} comptes clients sélectionnés`
								: `${this.state.selectedRows.length} compte client sélectionné`}
						</p>
						<div className="row-buttons">
							{this.state.selectedRows.length === 1 && (
								<button
									className="btn-update"
									type="button"
									onClick={this.handleOpenModalWithData}>
									<p>Modifier</p>
									<img src={ImgPencil} alt="Update" className="pencil" />
								</button>
							)}
							{!this.state.hasBalance && (
								<button
									className="btn-delete"
									type="button"
									onClick={this.handleOpenModal}>
									<p>Supprimer</p>
									<img src={ImgTrash} alt="Delete" className="trash" />
								</button>
							)}
						</div>
					</div>
				)}
				<main className="main_customer_account">
					<section>
						{this.state.loading ? (
							<TableLoader />
						) : this.state.listAccountsFiltered[0] &&
							this.state.listAccountsFiltered[0].length > 0 ? (
							<Clusterize
								rows={this.createAccountRow()}
								createTableHeader={this.createTableHeader()}
								isSuite={this.props.isSuite}
								selectAll={this.state.selectAll}
								handleSelectAll={this.handleSelectAll}
								onCheckboxChange={this.handleCheckboxChange}
							/>
						) : (
							<div id="empty-contact">
								<div id="error-box">
									<div className="face">
										¨
										<div className="eye" />
										<div className="eye right" />
										<div className="mouth sad" />
									</div>
									<div className="shadow move" />
									<p className="message">
										{I18n.t('customerAccount.noAccountFound')}
									</p>
								</div>
							</div>
						)}
					</section>
				</main>

				<Dialog
					open={this.state.modalOpen}
					onClose={this.handleCloseModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						Supprimer des comptes clients
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Voulez-vous vraiment supprimer cette sélection de comptes clients
							?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseModal}>Annuler</Button>
						<Button onClick={this.handleDelete} autoFocus>
							Valider
						</Button>
					</DialogActions>
				</Dialog>

				<ModalExport
					openModal={this.state.openExportModal}
					user={this.props.auth.user}
					onClose={() => {
						this.setState({ openExportModal: false });
					}}
					exportfct={this.exportCustomerData.bind(this)}
				/>

				<ModalHistory
					openModal={this.state.openExportHistory}
					user={this.props.auth.user}
					companies={this.props.companies}
					allUsers={this.state.allUsers}
					paymentTypes={this.state.paymentTypes}
					onDisplayMessage={this.props.addFlashMessage}
					onClose={() => {
						this.setState({ openExportHistory: false });
					}}
					onError={() => {
						this.props.addFlashMessage({
							type: 'danger',
							text: 'Une erreur est survenue',
							persistent: false,
						});
					}}
				/>

				<ModalSettings
					openModal={this.state.openSettings}
					onClose={() => {
						this.setState({ openSettings: false });
					}}
					onSuccess={() => {
						this.props.addFlashMessage({
							type: 'success',
							text: I18n.t('customerAccount.settingsSavedWithSuccess'),
							persistent: false,
						});
					}}
					onError={() => {
						this.props.addFlashMessage({
							type: 'danger',
							text: I18n.t('accountMigration.error'),
							persistent: false,
						});
					}}
				/>

				<ModalAccountForm
					openModal={this.state.openAccountForm}
					onClose={() => {
						this.setState({ openAccountForm: false });
					}}
					onSuccess={this.handleFormSuccess}
					accountData={this.state.selectedAccountData}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const user = state.auth.user;
	let isSuite = false;
	if (user && user.groups && Array.isArray(user.groups)) {
		isSuite = user.groups.some(group => group.name === 'laddition-suite');
	}
	return {
		auth: state.auth,
		companies: state.entities.informations.undefined.companies,
		isSuite,
	};
}

export default connect(mapStateToProps, { addFlashMessage })(CustomerAccount);
