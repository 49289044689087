import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import ClusterizeJS from "clusterize.js";
import { browserHistory } from "react-router";

class Clusterize extends Component {
  static propTypes = {
    rows: PropTypes.array,
    isSuite: PropTypes.bool,
    selectAll: PropTypes.bool,
    handleSelectAll: PropTypes.func,
    scrollTop: PropTypes.number,
    onCheckboxChange: PropTypes.func.isRequired,
    createTableHeader: PropTypes.func,
  };
  
  static defaultProps = {
    rows: [],
    isSuite: false,
    selectAll: false,
    handleSelectAll: () => {},
    scrollTop: 0,
  };

  clusterize = null;
  scrollElem = null;
  contentElem = null;

  componentDidMount() {
    this.initializeClusterize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rows !== this.props.rows) {
      this.updateClusterize();
    }
    if (prevProps.scrollTop !== this.props.scrollTop) {
      this.scrollElem.scrollTop = this.props.scrollTop;
    }
  }

  componentWillUnmount() {
    if (this.clusterize) {
      this.clusterize.destroy(true);
      this.clusterize = null;
    }
  }

  initializeClusterize() {
    const scrollElem = ReactDOM.findDOMNode(this.scrollElem);
    const contentElem = ReactDOM.findDOMNode(this.contentElem);

    const rows = this.props.rows.map(row => {
      if (typeof row === "string") {
        return row;
      }
      return React.isValidElement(row)
        ? ReactDOMServer.renderToString(row)
        : null;
    });

    this.clusterize = new ClusterizeJS({
      rows,
      scrollElem,
      contentElem,
      tag: "tr",
      show_no_data_row: false
    });

    this.attachEventListeners();
  }

  updateClusterize() {
    if (this.clusterize) {
      const rows = this.props.rows.map(row => {
        if (typeof row === "string") {
          return row;
        }
        return React.isValidElement(row)
          ? ReactDOMServer.renderToString(row)
          : null;
      });
      this.clusterize.update(rows);
      this.attachEventListeners();
    }
  }

  attachEventListeners() {
    const contentArea = document.getElementById("contentArea");
    if (contentArea) {
      contentArea.addEventListener('change', this.handleCheckboxChange);
    }
  }

  handleCheckboxChange = (event) => {
    if (event.target.type === 'checkbox') {
      const id = event.target.getAttribute('data-id');
      this.props.onCheckboxChange(id);
    }
  }

  renderHeader() {
    const { createTableHeader } = this.props;
    if (createTableHeader) {
      return (
        <div className="table-header" role="rowgroup">
          {this.props.isSuite && (
            <th id="header_label" className="flex-row check-box-header">
              <input
                type="checkbox"
                checked={this.props.selectAll}
                onChange={this.props.handleSelectAll}
              />
            </th>
          )}
          {createTableHeader}
        </div>
      )
    }
  }

  render() {
    const { className, style } = this.props;

    return (
      <div
        ref={node => {
          this.scrollElem = node;
        }}
        className={className}
        style={{
          height: "100%",
          overflow: "auto",
          ...style,
        }}
      >
        <div className="clusterize">
          <div id="scrollArea" className="clusterize-scroll">
            <table id="account-table" role="table">
              {this.renderHeader()}
              <tbody
                id="contentArea"
                className="clusterize-content"
                ref={node => {
                  this.contentElem = node;
                }}
              ></tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }  
}

export default Clusterize;
